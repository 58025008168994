/* Font-related custom properties */

:root {
	--font-size-default: 16px;
	--font-weight-light: 300;
	--font-weight-book: var(--font-weight-light);
	--font-weight-reg: 400;
	--font-weight-medium: 500;
	--font-weight-semi-bold: 600;
	--font-weight-bold: 700;
	--font-display: 'gilmer-web', 'Gilmer', Geneva, Tahoma, Helvetica, Verdana,
		sans-serif;
	--font-body: system-ui, sans-serif;
	--font-monospace: 'dejavu-sans-mono-web', monospace;
}
