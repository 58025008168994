:root {
	--hdsplus-color-boundary-button-color: #0c0c0e; 
	--hdsplus-color-boundary-button-background-color: #f24c53; 
	--hdsplus-color-boundary-button-background-color-hover: #df464c; 
	--hdsplus-color-boundary-button-background-color-active: #c63e44; 
	--hdsplus-color-boundary-button-background-color-focus: #f24c53; 
	--hdsplus-color-consul-button-color: #0c0c0e; 
	--hdsplus-color-consul-button-background-color: #e03875; 
	--hdsplus-color-consul-button-background-color-hover: #ca3269; 
	--hdsplus-color-consul-button-background-color-active: #b82e60; 
	--hdsplus-color-consul-button-background-color-focus: #e03875; 
	--hdsplus-color-nomad-button-color: #0c0c0e; 
	--hdsplus-color-nomad-button-background-color: #06d092; 
	--hdsplus-color-nomad-button-background-color-hover: #06bf86; 
	--hdsplus-color-nomad-button-background-color-active: #05b37e; 
	--hdsplus-color-nomad-button-background-color-focus: #06d092; 
	--hdsplus-color-packer-button-color: #0c0c0e; 
	--hdsplus-color-packer-button-background-color: #02a8ef; 
	--hdsplus-color-packer-button-background-color-hover: #029bdc; 
	--hdsplus-color-packer-button-background-color-active: #028dc9; 
	--hdsplus-color-packer-button-background-color-focus: #02a8ef; 
	--hdsplus-color-terraform-button-color: #ffffff; 
	--hdsplus-color-terraform-button-background-color: #7b42bc; 
	--hdsplus-color-terraform-button-background-color-hover: #713dad; 
	--hdsplus-color-terraform-button-background-color-active: #65369a; 
	--hdsplus-color-terraform-button-background-color-focus: #7b42bc; 
	--hdsplus-color-vagrant-button-color: #0c0c0e; 
	--hdsplus-color-vagrant-button-background-color: #1868f2; 
	--hdsplus-color-vagrant-button-background-color-hover: #1660df; 
	--hdsplus-color-vagrant-button-background-color-active: #1457cb; 
	--hdsplus-color-vagrant-button-background-color-focus: #1868f2; 
	--hdsplus-color-vault-button-color: #0c0c0e; 
	--hdsplus-color-vault-button-background-color: #ffcf25; 
	--hdsplus-color-vault-button-background-color-hover: #f0c323; 
	--hdsplus-color-vault-button-background-color-active: #e6ba21; 
	--hdsplus-color-vault-button-background-color-focus: #ffcf25; 
	--hdsplus-color-waypoint-button-color: #0c0c0e; 
	--hdsplus-color-waypoint-button-background-color: #14c6cb; 
	--hdsplus-color-waypoint-button-background-color-hover: #12b6bb; 
	--hdsplus-color-waypoint-button-background-color-active: #11aaaf; 
	--hdsplus-color-waypoint-button-background-color-focus: #14c6cb; 
	--hdsplus-color-primary-black-button-color: #efeff1; 
	--hdsplus-color-primary-black-button-background-color: #0d0e12; 
	--hdsplus-color-primary-black-button-background-color-hover: #202125; 
	--hdsplus-color-primary-black-button-background-color-active: #39393d; 
	--hdsplus-color-primary-black-button-background-color-focus: #0d0e12; 
	--hdsplus-color-primary-black-button-border-color: #0d0e12; 
	--hdsplus-color-primary-white-button-color: #0c0c0e; 
	--hdsplus-color-primary-white-button-background-color: #ffffff; 
	--hdsplus-color-primary-white-button-background-color-hover: #dedfe0; 
	--hdsplus-color-primary-white-button-background-color-active: #c6c6c7; 
	--hdsplus-color-primary-white-button-background-color-focus: #f1f2f3; 
	--hdsplus-color-secondary-black-button-color: #d5d7db; 
	--hdsplus-color-secondary-black-button-background-color: #15181e; 
	--hdsplus-color-secondary-black-button-background-color-hover: #282a30; 
	--hdsplus-color-secondary-black-button-background-color-active: #3f4247; 
	--hdsplus-color-secondary-black-button-background-color-focus: #15181e; 
	--hdsplus-color-secondary-black-button-border-color: #3b3d4566; 
	--hdsplus-color-secondary-white-button-color: #3b3d45; 
	--hdsplus-color-secondary-white-button-background-color: #fafafa; 
	--hdsplus-color-secondary-white-button-background-color-hover: #fbfbfb; 
	--hdsplus-color-secondary-white-button-background-color-active: #cdcdcd; 
	--hdsplus-color-secondary-white-button-background-color-focus: #fafafa; 
	--hdsplus-color-secondary-white-button-border-color: #3b3d4566; 
	--hdsplus-typography-font-stack-display-expressive: Geneva, Tahoma, Helvetica, Verdana, sans-serif; 
	--hdsplus-typography-body-300-font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; 
	--hdsplus-typography-body-300-font-size: 1rem; /* 16px/1rem */
	--hdsplus-typography-body-300-line-height: 1.6875; /* 24px */
	--hdsplus-typography-body-200-font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; 
	--hdsplus-typography-body-200-font-size: 0.875rem; /* 14px/0.875rem */
	--hdsplus-typography-body-200-line-height: 1.7142857142857142; /* 20px */
	--hdsplus-typography-display-expressive-100-font-family: Geneva, Tahoma, Helvetica, Verdana, sans-serif; 
	--hdsplus-typography-display-expressive-100-font-size: 1.0625rem; 
	--hdsplus-typography-display-expressive-100-line-height: 1.1764705882; 
	--hdsplus-typography-display-expressive-100-letter-spacing: 0em; 
	--hdsplus-typography-display-expressive-200-font-family: Geneva, Tahoma, Helvetica, Verdana, sans-serif; 
	--hdsplus-typography-display-expressive-200-font-size: 1.1875rem; 
	--hdsplus-typography-display-expressive-200-line-height: 1.2105263158; 
	--hdsplus-typography-display-expressive-200-letter-spacing: 0em; 
	--hdsplus-typography-display-expressive-300-font-family: Geneva, Tahoma, Helvetica, Verdana, sans-serif; 
	--hdsplus-typography-display-expressive-300-font-size: 1.625rem; 
	--hdsplus-typography-display-expressive-300-line-height: 1.1923076923; 
	--hdsplus-typography-display-expressive-300-letter-spacing: 0em; 
	--hdsplus-typography-display-expressive-400-font-family: Geneva, Tahoma, Helvetica, Verdana, sans-serif; 
	--hdsplus-typography-display-expressive-400-font-size: 2.125rem; 
	--hdsplus-typography-display-expressive-400-line-height: 1.1764705882; 
	--hdsplus-typography-display-expressive-400-letter-spacing: 0em; 
	--hdsplus-typography-display-expressive-500-font-family: Geneva, Tahoma, Helvetica, Verdana, sans-serif; 
	--hdsplus-typography-display-expressive-500-font-size: 2.625rem; 
	--hdsplus-typography-display-expressive-500-line-height: 1.1904761905; 
	--hdsplus-typography-display-expressive-500-letter-spacing: 0em; 
	--hdsplus-typography-display-expressive-600-font-family: Geneva, Tahoma, Helvetica, Verdana, sans-serif; 
	--hdsplus-typography-display-expressive-600-font-size: 3.25rem; 
	--hdsplus-typography-display-expressive-600-line-height: 1.1923076923; 
	--hdsplus-typography-display-expressive-600-letter-spacing: 0em; 
	--hdsplus-typography-display-expressive-700-font-family: Geneva, Tahoma, Helvetica, Verdana, sans-serif; 
	--hdsplus-typography-display-expressive-700-font-size: 4.125rem; 
	--hdsplus-typography-display-expressive-700-line-height: 1.1969696970; 
	--hdsplus-typography-display-expressive-700-letter-spacing: 0em; 
	--hdsplus-typography-display-expressive-800-font-family: Geneva, Tahoma, Helvetica, Verdana, sans-serif; 
	--hdsplus-typography-display-expressive-800-font-size: 5.125rem; 
	--hdsplus-typography-display-expressive-800-line-height: 1.1707317073; 
	--hdsplus-typography-display-expressive-800-letter-spacing: 0em; 
	--hdsplus-typography-body-400-font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; 
	--hdsplus-typography-body-400-font-size: 1.25rem; 
	--hdsplus-typography-body-400-line-height: 1.5; 
	--hdsplus-typography-label-font-family: Geneva, Tahoma, Helvetica, Verdana, sans-serif; 
	--hdsplus-typography-label-font-size: 0.8125rem; 
	--hdsplus-typography-label-line-height: 1.6923076923; 
	--hdsplus-typography-label-letter-spacing: 0.1em; 
	--hdsplus-typography-label-text-transform: uppercase; 
	--hdsplus-spacing-10: 3rem; /* 48px */
	--hdsplus-spacing-11: 3.5rem; /* 56px */
	--hdsplus-spacing-12: 4rem; /* 64px */
	--hdsplus-spacing-13: 4.5rem; /* 72px */
	--hdsplus-spacing-14: 5.5rem; /* 88px */
	--hdsplus-spacing-15: 6rem; /* 96px */
	--hdsplus-spacing-16: 8rem; /* 128px */
	--hdsplus-spacing-17: 8.5rem; /* 144px */
	--hdsplus-spacing-18: 10rem; /* 160px */
	--hdsplus-spacing-01: 0.25rem; /* 4px */
	--hdsplus-spacing-02: 0.375rem; /* 6px */
	--hdsplus-spacing-03: 0.5rem; /* 8px */
	--hdsplus-spacing-04: 0.75rem; /* 12px */
	--hdsplus-spacing-05: 1rem; /* 16px */
	--hdsplus-spacing-06: 1.25rem; /* 20px */
	--hdsplus-spacing-07: 1.5rem; /* 24px */
	--hdsplus-spacing-08: 2rem; /* 32px */
	--hdsplus-spacing-09: 2.5rem; /* 40px */

	/* DARK MODE TOKENS */
	--hdsplus-color-dark-palette-blue-500: #6bc1ff; 
	--hdsplus-color-dark-palette-blue-400: #4ca1ff; 
	--hdsplus-color-dark-palette-blue-300: #389aff; 
	--hdsplus-color-dark-palette-blue-200: #2b89ff; 
	--hdsplus-color-dark-palette-blue-100: #1c345f; 
	--hdsplus-color-dark-palette-blue-50: #111f37; 
	--hdsplus-color-dark-palette-purple-500: #dda5ff; 
	--hdsplus-color-dark-palette-purple-400: #c070ff; 
	--hdsplus-color-dark-palette-purple-300: #b457ff; 
	--hdsplus-color-dark-palette-purple-200: #ab42ff; 
	--hdsplus-color-dark-palette-purple-100: #42225b; 
	--hdsplus-color-dark-palette-purple-50: #29123a; 
	--hdsplus-color-dark-palette-green-500: #1ce375; 
	--hdsplus-color-dark-palette-green-400: #00c157; 
	--hdsplus-color-dark-palette-green-300: #00ae4e; 
	--hdsplus-color-dark-palette-green-200: #009241; 
	--hdsplus-color-dark-palette-green-100: #054220; 
	--hdsplus-color-dark-palette-green-50: #042a15; 
	--hdsplus-color-dark-palette-amber-500: #ffcc6a; 
	--hdsplus-color-dark-palette-amber-400: #faad3a; 
	--hdsplus-color-dark-palette-amber-300: #fda219; 
	--hdsplus-color-dark-palette-amber-200: #e88c03; 
	--hdsplus-color-dark-palette-amber-100: #542800; 
	--hdsplus-color-dark-palette-amber-50: #2e1b06; 
	--hdsplus-color-dark-palette-red-500: #ff8c7c; 
	--hdsplus-color-dark-palette-red-400: #ff5841; 
	--hdsplus-color-dark-palette-red-300: #f9381e; 
	--hdsplus-color-dark-palette-red-200: #ef3016; 
	--hdsplus-color-dark-palette-red-100: #62170d; 
	--hdsplus-color-dark-palette-red-50: #370b06; 
	--hdsplus-color-dark-palette-neutral-700: #efeff1; 
	--hdsplus-color-dark-palette-neutral-600: #d5d7db; 
	--hdsplus-color-dark-palette-neutral-500: #b2b6bd; 
	--hdsplus-color-dark-palette-neutral-400: #616875; 
	--hdsplus-color-dark-palette-neutral-300: #434956; 
	--hdsplus-color-dark-palette-neutral-200: #2b303c; 
	--hdsplus-color-dark-palette-neutral-100: #1e222a; 
	--hdsplus-color-dark-palette-neutral-50: #15181e; 
	--hdsplus-color-dark-palette-neutral-0: #0d0e12; 
	--hdsplus-color-dark-palette-alpha-300: #b2b6bd66; 
	--hdsplus-color-dark-palette-alpha-200: #b2b6bd33; 
	--hdsplus-color-dark-palette-alpha-100: #b2b6bd1a; 
}