.g-type-display-1 {
	font-family: var(--hdsplus-typography-display-expressive-400-font-family);
	font-size: var(--hdsplus-typography-display-expressive-400-font-size);
	line-height: var(--hdsplus-typography-display-expressive-400-line-height);
	letter-spacing: var(
		--hdsplus-typography-display-expressive-400-letter-spacing
	);

	font-weight: var(--token-typography-font-weight-bold);

	@media (--medium-up) {
		font-family: var(--hdsplus-typography-display-expressive-500-font-family);
		font-size: var(--hdsplus-typography-display-expressive-500-font-size);
		line-height: var(--hdsplus-typography-display-expressive-500-line-height);
		letter-spacing: var(
			--hdsplus-typography-display-expressive-500-letter-spacing
		);
	}

	@media (--large) {
		font-family: var(--hdsplus-typography-display-expressive-600-font-family);
		font-size: var(--hdsplus-typography-display-expressive-600-font-size);
		line-height: var(--hdsplus-typography-display-expressive-600-line-height);
		letter-spacing: var(
			--hdsplus-typography-display-expressive-600-letter-spacing
		);
	}
}

/* Display 2 */
.g-type-display-2 {
	font-family: var(--hdsplus-typography-display-expressive-300-font-family);
	font-size: var(--hdsplus-typography-display-expressive-300-font-size);
	line-height: var(--hdsplus-typography-display-expressive-300-line-height);
	letter-spacing: var(
		--hdsplus-typography-display-expressive-300-letter-spacing
	);

	font-weight: bold;

	@media (--medium-up) {
		font-family: var(--hdsplus-typography-display-expressive-400-font-family);
		font-size: var(--hdsplus-typography-display-expressive-400-font-size);
		line-height: var(--hdsplus-typography-display-expressive-400-line-height);
		letter-spacing: var(
			--hdsplus-typography-display-expressive-400-letter-spacing
		);
	}

	@media (--large) {
		font-family: var(--hdsplus-typography-display-expressive-500-font-family);
		font-size: var(--hdsplus-typography-display-expressive-500-font-size);
		line-height: var(--hdsplus-typography-display-expressive-500-line-height);
		letter-spacing: var(
			--hdsplus-typography-display-expressive-500-letter-spacing
		);
	}
}

/* Display 3 */
.g-type-display-3 {
	font-family: var(--hdsplus-typography-display-expressive-200-font-family);
	font-size: var(--hdsplus-typography-display-expressive-200-font-size);
	line-height: var(--hdsplus-typography-display-expressive-200-line-height);
	letter-spacing: var(
		--hdsplus-typography-display-expressive-200-letter-spacing
	);

	font-weight: bold;

	@media (--medium-up) {
		font-family: var(--hdsplus-typography-display-expressive-300-font-family);
		font-size: var(--hdsplus-typography-display-expressive-300-font-size);
		line-height: var(--hdsplus-typography-display-expressive-300-line-height);
		letter-spacing: var(
			--hdsplus-typography-display-expressive-300-letter-spacing
		);
	}

	@media (--large) {
		font-family: var(--hdsplus-typography-display-expressive-400-font-family);
		font-size: var(--hdsplus-typography-display-expressive-400-font-size);
		line-height: var(--hdsplus-typography-display-expressive-400-line-height);
		letter-spacing: var(
			--hdsplus-typography-display-expressive-400-letter-spacing
		);
	}
}

/* Display 4 */
.g-type-display-4 {
	font-family: var(--hdsplus-typography-display-expressive-100-font-family);
	font-size: var(--hdsplus-typography-display-expressive-100-font-size);
	line-height: var(--hdsplus-typography-display-expressive-100-line-height);
	letter-spacing: var(
		--hdsplus-typography-display-expressive-100-letter-spacing
	);

	font-weight: bold;

	@media (--medium-up) {
		font-family: var(--hdsplus-typography-display-expressive-200-font-family);
		font-size: var(--hdsplus-typography-display-expressive-200-font-size);
		line-height: var(--hdsplus-typography-display-expressive-200-line-height);
		letter-spacing: var(
			--hdsplus-typography-display-expressive-200-letter-spacing
		);
	}

	@media (--large) {
		font-family: var(--hdsplus-typography-display-expressive-300-font-family);
		font-size: var(--hdsplus-typography-display-expressive-300-font-size);
		line-height: var(--hdsplus-typography-display-expressive-300-line-height);
		letter-spacing: var(
			--hdsplus-typography-display-expressive-300-letter-spacing
		);
	}
}

/* Display 5 */
.g-type-display-5 {
	font-family: var(--hdsplus-typography-display-expressive-200-font-family);
	font-size: var(--hdsplus-typography-display-expressive-200-font-size);
	line-height: var(--hdsplus-typography-display-expressive-200-line-height);
	letter-spacing: var(
		--hdsplus-typography-display-expressive-200-letter-spacing
	);

	font-weight: bold;
}

/* Display 6 */
.g-type-display-6 {
	font-family: var(--hdsplus-typography-display-expressive-100-font-family);
	font-size: var(--hdsplus-typography-display-expressive-100-font-size);
	line-height: var(--hdsplus-typography-display-expressive-100-line-height);
	letter-spacing: var(
		--hdsplus-typography-display-expressive-100-letter-spacing
	);

	font-weight: bold;
}

/* Body Large */
.g-type-body-large {
	font-family: var(--hdsplus-typography-body-400-font-family);
	font-size: var(--hdsplus-typography-body-400-font-size);
	line-height: var(--hdsplus-typography-body-400-line-height);
	letter-spacing: var(--hdsplus-typography-body-400-letter-spacing);

	font-weight: var(--token-typography-font-weight-regular);
}

/* Body */
.g-type-body {
	font-family: var(--hdsplus-typography-body-300-font-family);
	font-size: var(--hdsplus-typography-body-300-font-size);
	line-height: var(--hdsplus-typography-body-300-line-height);
}

/* Body - Strong */
.g-type-body-strong {
	font-family: var(--hdsplus-typography-body-300-font-family);
	font-size: var(--hdsplus-typography-body-300-font-size);
	line-height: var(--hdsplus-typography-body-300-line-height);

	font-weight: var(--token-typography-font-weight-medium);
}

/* Body - X Strong */
.g-type-body-x-strong {
	font-family: var(--hdsplus-typography-body-300-font-family);
	font-size: var(--hdsplus-typography-body-300-font-size);
	line-height: var(--hdsplus-typography-body-300-line-height);

	font-weight: var(--token-typography-font-weight-bold);
}

/* Body - Italic */
.g-type-body-italic {
	font-family: var(--hdsplus-typography-body-300-font-family);
	font-size: var(--hdsplus-typography-body-300-font-size);
	line-height: var(--hdsplus-typography-body-300-line-height);

	font-style: italic;
}

/* Body Small */
.g-type-body-small {
	font-family: var(--hdsplus-typography-body-200-font-family);
	font-size: var(--hdsplus-typography-body-200-font-size);
	line-height: var(--hdsplus-typography-body-200-line-height);
}

/* Body Small - Strong */
.g-type-body-small-strong {
	font-family: var(--hdsplus-typography-body-200-font-family);
	font-size: var(--hdsplus-typography-body-200-font-size);
	line-height: var(--hdsplus-typography-body-200-line-height);

	font-weight: var(--token-typography-font-weight-medium);
}

/* Body Small - X Strong */
.g-type-body-small-x-strong {
	font-family: var(--hdsplus-typography-body-200-font-family);
	font-size: var(--hdsplus-typography-body-200-font-size);
	line-height: var(--hdsplus-typography-body-200-line-height);

	font-weight: var(--token-typography-font-weight-bold);
}

/* Body Small - Italic */
.g-type-body-small-italic {
	font-family: var(--hdsplus-typography-body-200-font-family);
	font-size: var(--hdsplus-typography-body-200-font-size);
	line-height: var(--hdsplus-typography-body-200-line-height);

	font-style: italic;
}

/* Long Body */
.g-type-long-body {
	font-family: var(--hdsplus-typography-body-300-font-family);
	font-size: var(--hdsplus-typography-body-300-font-size);
	line-height: var(--hdsplus-typography-body-300-line-height);
}

/* Long Body Strong */
.g-type-long-body-strong {
	font-family: var(--hdsplus-typography-body-300-font-family);
	font-size: var(--hdsplus-typography-body-300-font-size);
	line-height: var(--hdsplus-typography-body-300-line-height);

	font-weight: var(--token-typography-font-weight-medium);
}

/* Long Body Italic */
.g-type-long-body-italic {
	font-family: var(--hdsplus-typography-body-300-font-family);
	font-size: var(--hdsplus-typography-body-300-font-size);
	line-height: var(--hdsplus-typography-body-300-line-height);

	font-style: italic;
}

/* Buttons and standalone links */
.g-type-buttons-and-standalone-links {
	font-family: var(--hdsplus-typography-body-300-font-family);
	font-size: var(--hdsplus-typography-body-300-font-size);
	line-height: var(--hdsplus-typography-body-300-line-height);

	font-weight: var(--token-typography-font-weight-medium);
}

/* Header Nav */
.g-type-header-nav {
	font-family: var(--hdsplus-typography-display-expressive-100-font-family);
	font-size: var(--hdsplus-typography-display-expressive-100-font-size);
	line-height: var(--hdsplus-typography-display-expressive-100-line-height);
	letter-spacing: var(
		--hdsplus-typography-display-expressive-100-letter-spacing
	);

	font-weight: 500;
}

/* Label */
.g-type-label {
	font-family: var(--hdsplus-typography-label-font-family);
	font-size: var(--hdsplus-typography-label-font-size);
	line-height: var(--hdsplus-typography-label-line-height);
	letter-spacing: var(--hdsplus-typography-label-letter-spacing);

	text-transform: uppercase;
}

/* Label - Strong */
.g-type-label-strong {
	font-family: var(--hdsplus-typography-label-font-family);
	font-size: var(--hdsplus-typography-label-font-size);
	line-height: var(--hdsplus-typography-label-line-height);
	letter-spacing: var(--hdsplus-typography-label-letter-spacing);

	text-transform: uppercase;
	font-weight: var(--token-typography-font-weight-medium);
}

.g-type-label-small {
	font-family: var(--hdsplus-typography-label-font-family);
	font-size: var(--hdsplus-typography-label-font-size);
	line-height: var(--hdsplus-typography-label-line-height);
	letter-spacing: var(--hdsplus-typography-label-letter-spacing);

	text-transform: uppercase;
}

.g-type-label-small-strong {
	font-family: var(--hdsplus-typography-label-font-family);
	font-size: var(--hdsplus-typography-label-font-size);
	line-height: var(--hdsplus-typography-label-line-height);
	letter-spacing: var(--hdsplus-typography-label-letter-spacing);

	text-transform: uppercase;
	font-weight: var(--token-typography-font-weight-medium);
}

/* Tag Label */
.g-type-tag-label {
	font-family: var(--hdsplus-typography-label-font-family);
	font-size: var(--hdsplus-typography-label-font-size);
	line-height: var(--hdsplus-typography-label-line-height);
	letter-spacing: var(--hdsplus-typography-label-letter-spacing);

	font-weight: var(--token-typography-font-weight-bold);
}

/* Code */
.g-type-code {
	font-family: var(--token-typography-code-200-font-family);
	font-size: var(--token-typography-code-200-font-size);
	line-height: var(--token-typography-code-200-line-height);
	margin: 0;
	padding: 0;
}
