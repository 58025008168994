/**
  * Web Pattern Library spacing tokens
  * https://www.figma.com/file/8iryEikGV4Z5fVYmQH4WTi/01---Visual-Guidelines-v3.0?node-id=6276%3A65
  */
:root {
	--wpl-spacing-01: 8px;
	--wpl-spacing-02: 12px;
	--wpl-spacing-03: 16px;
	--wpl-spacing-04: 20px;
	--wpl-spacing-05: 24px;
	--wpl-spacing-06: 32px;
	--wpl-spacing-07: 40px;
	--wpl-spacing-08: 48px;
	--wpl-spacing-09: 56px;
	--wpl-spacing-10: 64px;
	--wpl-spacing-11: 72px;
	--wpl-spacing-12: 80px;
	--wpl-spacing-13: 88px;
	--wpl-spacing-14: 96px;
	--wpl-spacing-15: 128px;
}
