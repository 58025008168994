.hdsplus-dark-mode, [data-theme="dark"] {
  --token-color-palette-blue-500: #6bc1ff;
  --token-color-palette-blue-400: #4ca1ff;
  --token-color-palette-blue-300: #389aff;
  --token-color-palette-blue-200: #2b89ff;
  --token-color-palette-blue-100: #1c345f;
  --token-color-palette-blue-50: #111f37;
  --token-color-palette-purple-500: #dda5ff;
  --token-color-palette-purple-400: #c070ff;
  --token-color-palette-purple-300: #b457ff;
  --token-color-palette-purple-200: #ab42ff;
  --token-color-palette-purple-100: #42225b;
  --token-color-palette-purple-50: #29123a;
  --token-color-palette-green-500: #1ce375;
  --token-color-palette-green-400: #00c157;
  --token-color-palette-green-300: #00ae4e;
  --token-color-palette-green-200: #009241;
  --token-color-palette-green-100: #054220;
  --token-color-palette-green-50: #042a15;
  --token-color-palette-amber-500: #ffcc6a;
  --token-color-palette-amber-400: #faad3a;
  --token-color-palette-amber-300: #fda219;
  --token-color-palette-amber-200: #e88c03;
  --token-color-palette-amber-100: #542800;
  --token-color-palette-amber-50: #2e1b06;
  --token-color-palette-red-500: #ff8c7c;
  --token-color-palette-red-400: #ff5841;
  --token-color-palette-red-300: #f9381e;
  --token-color-palette-red-200: #ef3016;
  --token-color-palette-red-100: #62170d;
  --token-color-palette-red-50: #370b06;
  --token-color-palette-neutral-700: #efeff1;
  --token-color-palette-neutral-600: #d5d7db;
  --token-color-palette-neutral-500: #b2b6bd;
  --token-color-palette-neutral-400: #616875;
  --token-color-palette-neutral-300: #434956;
  --token-color-palette-neutral-200: #2b303c;
  --token-color-palette-neutral-100: #1e222a;
  --token-color-palette-neutral-50: #15181e;
  --token-color-palette-neutral-0: #0d0e12;
  --token-color-palette-alpha-300: #b2b6bd66;
  --token-color-palette-alpha-200: #b2b6bd33;
  --token-color-palette-alpha-100: #b2b6bd1a;
  --token-color-border-primary: #b2b6bd33;
  --token-color-border-faint: #b2b6bd1a;
  --token-color-border-strong: #b2b6bd66;
  --token-color-border-action: #1c345f;
  --token-color-border-highlight: #42225b;
  --token-color-border-success: #054220;
  --token-color-border-warning: #542800;
  --token-color-border-critical: #62170d;
  --token-color-focus-action-internal: #389aff;
  --token-color-focus-action-external: #5990ff; /* this is a special color used only for the focus style, to pass color contrast for a11y purpose */
  --token-color-focus-critical-internal: #f9381e;
  --token-color-focus-critical-external: #dd7578; /* this is a special color used only for the focus style, to pass color contrast for a11y purpose */
  --token-color-foreground-strong: #efeff1;
  --token-color-foreground-primary: #d5d7db;
  --token-color-foreground-faint: #b2b6bd;
  --token-color-foreground-high-contrast: #0d0e12;
  --token-color-foreground-disabled: #616875;
  --token-color-foreground-action: #2b89ff;
  --token-color-foreground-action-hover: #389aff;
  --token-color-foreground-action-active: #4ca1ff;
  --token-color-foreground-highlight: #ab42ff;
  --token-color-foreground-highlight-on-surface: #b457ff;
  --token-color-foreground-highlight-high-contrast: #dda5ff;
  --token-color-foreground-success: #009241;
  --token-color-foreground-success-on-surface: #00ae4e;
  --token-color-foreground-success-high-contrast: #1ce375;
  --token-color-foreground-warning: #e88c03;
  --token-color-foreground-warning-on-surface: #fda219;
  --token-color-foreground-warning-high-contrast: #ffcc6a;
  --token-color-foreground-critical: #ef3016;
  --token-color-foreground-critical-on-surface: #f9381e;
  --token-color-foreground-critical-high-contrast: #ff8c7c;
  --token-color-foreground-action-visited: #b457ff;
  --token-color-foreground-action-visited-hover: #c070ff;
  --token-color-page-primary: #0d0e12;
  --token-color-page-faint: #15181e;
  --token-color-surface-primary: #0d0e12;
  --token-color-surface-faint: #15181e;
  --token-color-surface-strong: #1e222a;
  --token-color-surface-interactive: #0d0e12;
  --token-color-surface-interactive-hover: #1e222a;
  --token-color-surface-interactive-active: #2b303c;
  --token-color-surface-interactive-disabled: #15181e;
  --token-color-surface-action: #111f37;
  --token-color-surface-highlight: #29123a;
  --token-color-surface-success: #042a15;
  --token-color-surface-warning: #2e1b06;
  --token-color-surface-critical: #370b06;
  --token-color-hashicorp-brand: #fff;
  --token-color-boundary-brand: #f24c53;
  --token-color-boundary-foreground: #f2474c;
  --token-color-boundary-surface: #351114;
  --token-color-boundary-border: #731316;
  --token-color-boundary-gradient-primary-start: #ff9da1;
  --token-color-boundary-gradient-primary-stop: #f2474c;
  --token-color-boundary-gradient-faint-start: #351114; /* this is the 'boundary-50' value at 25% opacity on white */
  --token-color-boundary-gradient-faint-stop: #1f0e11;
  --token-color-consul-brand: #e03875;
  --token-color-consul-foreground: #ed3b7c;
  --token-color-consul-surface: #350b1c;
  --token-color-consul-border: #830833;
  --token-color-consul-gradient-primary-start: #ff99be;
  --token-color-consul-gradient-primary-stop: #ed3b7c;
  --token-color-consul-gradient-faint-start: #350b1c; /* this is the 'consul-50' value at 25% opacity on white */
  --token-color-consul-gradient-faint-stop: #1e0c14;
  --token-color-hcp-brand: #fff;
  --token-color-nomad-brand: #06d092;
  --token-color-nomad-foreground: #3ebf89;
  --token-color-nomad-surface: #0a2720;
  --token-color-nomad-border: #227d58;
  --token-color-nomad-gradient-primary-start: #bff3dd;
  --token-color-nomad-gradient-primary-stop: #3ebf89;
  --token-color-nomad-gradient-faint-start: #0a2720; /* this is the 'nomad-50' value at 25% opacity on white */
  --token-color-nomad-gradient-faint-stop: #0b1816;
  --token-color-packer-brand: #02a8ef;
  --token-color-packer-foreground: #32b8f2;
  --token-color-packer-surface: #0a2532;
  --token-color-packer-border: #237fa7;
  --token-color-packer-gradient-primary-start: #b4e4ff;
  --token-color-packer-gradient-primary-stop: #32b8f2;
  --token-color-packer-gradient-faint-start: #0a2532; /* this is the 'packer-50' value at 25% opacity on white */
  --token-color-packer-gradient-faint-stop: #0b171e;
  --token-color-terraform-brand: #7b42bc;
  --token-color-terraform-foreground: #a067da;
  --token-color-terraform-surface: #211131;
  --token-color-terraform-border: #633690;
  --token-color-terraform-gradient-primary-start: #d8b7fc;
  --token-color-terraform-gradient-primary-stop: #a067da;
  --token-color-terraform-gradient-faint-start: #211131; /* this is the 'terraform-50' value at 25% opacity on white */
  --token-color-terraform-gradient-faint-stop: #150f1e;
  --token-color-vagrant-brand: #1868f2;
  --token-color-vagrant-foreground: #3a80fa;
  --token-color-vagrant-surface: #0b1c39;
  --token-color-vagrant-border: #2354aa;
  --token-color-vagrant-gradient-primary-start: #7dadff;
  --token-color-vagrant-gradient-primary-stop: #3a80fa;
  --token-color-vagrant-gradient-faint-start: #0b1c39; /* this is the 'vagrant-50' value at 25% opacity on white */
  --token-color-vagrant-gradient-faint-stop: #0c1322;
  --token-color-vault-brand: #ffdd04;
  --token-color-vault-brand-alt: #ffffff; /* this is a special “alternative” color, used as an exception in some contexts where the normal “brand” color for Vault would not work */
  --token-color-vault-foreground: #ffdd04;
  --token-color-vault-surface: #1e2328;
  --token-color-vault-border: #8f7d09;
  --token-color-vault-gradient-primary-start: #feec7b;
  --token-color-vault-gradient-primary-stop: #ffdd04;
  --token-color-vault-gradient-faint-start: #1e2328; /* this is the 'vault-50' value at 25% opacity on white */
  --token-color-vault-gradient-faint-stop: #13161a;
  --token-color-waypoint-brand: #14c6cb;
  --token-color-waypoint-foreground: #4abfc7;
  --token-color-waypoint-surface: #0a262c;
  --token-color-waypoint-border: #1d7177;
  --token-color-waypoint-gradient-primary-start: #cbf1f3;
  --token-color-waypoint-gradient-primary-stop: #4abfc7;
  --token-color-waypoint-gradient-faint-start: #0a262c; /* this is the 'waypoint-50' value at 25% opacity on white */
  --token-color-waypoint-gradient-faint-stop: #0b181c;
  --token-elevation-high-box-shadow: 0 2px 3px 0 #61687526, 0 16px 16px -10px #61687533;
  --token-elevation-higher-box-shadow: 0 2px 3px 0 #4349561a, 0 12px 28px 0 #43495640;
  --token-elevation-inset-box-shadow: inset 0 1px 2px 1px #6168751a;
  --token-elevation-low-box-shadow: 0 1px 1px 0 #6168750d, 0 2px 2px 0 #6168750d;
  --token-elevation-mid-box-shadow: 0 2px 3px 0 #6168751a, 0 8px 16px -10px #6168751a;
  --token-elevation-overlay-box-shadow: 0 2px 3px 0 #43495640, 0 12px 24px 0 #43495659;
  --token-surface-inset-box-shadow: inset 0 0 0 1px #b2b6bd4d, inset 0 1px 2px 1px #6168751a;
  --token-surface-base-box-shadow: 0 0 0 1px #b2b6bd33;
  --token-surface-low-box-shadow: 0 0 0 1px #b2b6bd26, 0 1px 1px 0 #6168750d, 0 2px 2px 0 #6168750d;
  --token-surface-mid-box-shadow: 0 0 0 1px #b2b6bd26, 0 2px 3px 0 #6168751a, 0 8px 16px -10px #61687533;
  --token-surface-high-box-shadow: 0 0 0 1px #b2b6bd40, 0 2px 3px 0 #61687526, 0 16px 16px -10px #61687533;
  --token-surface-higher-box-shadow: 0 0 0 1px #b2b6bd33, 0 2px 3px 0 #4349561a, 0 12px 28px 0 #43495640;
  --token-surface-overlay-box-shadow: 0 0 0 1px #b2b6bd40, 0 2px 3px 0 #43495640, 0 12px 24px 0 #43495659;;
  --token-focus-ring-action-box-shadow: inset 0 0 0 1px var(--token-color-palette-blue-300), 0 0 0 3px #5990ff;
  --token-focus-ring-critical-box-shadow: inset 0 0 0 1px var(--token-color-palette-red-300), 0 0 0 3px var(--token-color-palette-red-400);
  --token-form-control-base-foreground-value-color: #efeff1;
  --token-form-control-base-foreground-placeholder-color: #b2b6bd;
  --token-form-control-base-surface-color-default: #0d0e12;
  --token-form-control-base-surface-color-hover: #1e222a;
  --token-form-control-base-border-color-default: #616875;
  --token-form-control-base-border-color-hover: #b2b6bd;
  --token-form-control-checked-foreground-color: #0d0e12;
  --token-form-control-checked-surface-color-default: #2b89ff;
  --token-form-control-checked-surface-color-hover: #389aff;
  --token-form-control-checked-border-color-default: #389aff;
  --token-form-control-checked-border-color-hover: #4ca1ff;
  --token-form-control-invalid-border-color-default: #f9381e;
  --token-form-control-invalid-border-color-hover: #ff5841;
  --token-form-control-readonly-foreground-color: #d5d7db;
  --token-form-control-readonly-surface-color: #1e222a;
  --token-form-control-readonly-border-color: #656a761a;
  --token-form-control-disabled-foreground-color: #616875;
  --token-form-control-disabled-surface-color: #15181e;
  --token-form-control-disabled-border-color: #656a7633;
  --token-form-label-color: #efeff1;
  --token-form-legend-color: #efeff1;
  --token-form-helper-text-color: #b2b6bd;
  --token-form-indicator-optional-color: #b2b6bd;
  --token-form-error-color: #f9381e;
  --token-form-toggle-base-surface-color-default: #1e222a; /* the toggle has a different base surface color, compared to the other controls */
}
