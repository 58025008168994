/*
   * DejaVu
   *
   * DejaVu Sans Mono is a monospaced sans-serif typeface, based on the Bitstream
   * Vera Monospace typeface designed by Jim Lyles. It should be used for all
   * code snippets or labels.
   */

@font-face {
	font-display: swap;
	font-family: 'dejavu-sans-mono-web';
	font-style: normal;
	font-weight: 400;
	src: local('DejaVu Sans Mono'),
		url('./font/dejavu_sans_mono-400-normal.woff2') format('woff2'),
		url('./font/dejavu_sans_mono-400-normal.woff') format('woff');
}
