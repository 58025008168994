/* CSS version of focus ring mixin with standalone link values
https://github.com/hashicorp/web/blob/b3204dfc7089220bd995316c302d94d87d858b15/packages/react-hds/src/mixins/focus-ring.scss
 */

.hds-focus-ring-basic {
	outline-style: solid;
	outline-color: transparent;

	&:focus,
	&.mock-focus {
		box-shadow: var(--token-focus-ring-action-box-shadow);
	}

	&:focus:not(:focus-visible) {
		box-shadow: none;
	}

	&:focus-visible {
		box-shadow: var(--token-focus-ring-action-box-shadow);
	}

	&:focus:active,
	&.mock-focus.mock-active {
		box-shadow: none;
	}
}

.hds-focus-ring-with-pseudo-element {
	position: relative;
	outline-style: solid;
	outline-color: transparent;
	isolation: isolate;

	&::before {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		border-radius: 5px;
		content: '';
	}

	&:focus,
	&.mock-focus {
		&::before {
			box-shadow: var(--token-focus-ring-action-box-shadow);
		}
	}

	&:focus:not(:focus-visible) {
		&::before {
			box-shadow: none;
		}
	}

	&:focus-visible {
		&::before {
			box-shadow: var(--token-focus-ring-action-box-shadow);
		}
	}

	&:focus:active,
	&.mock-focus.mock-active {
		&::before {
			box-shadow: none;
		}
	}
}
