/* Color Tokens */

/**
 V2 Colors -----------
  --brand-l3: #eff5ff;
  --brand-l2: #d0e0ff;
  --brand-l1: #66a2ff;
  --brand: #1563ff;
  --brand-on-dark: #3d89ff;
  --brand-text: #1563ff;
  --brand-text-on-dark: #3d89ff;
  --brand-d1: #0d44cc;
  --brand-d2: #08368b;

V3 Colors ---------- This pattern is consistent with all product variations
  --brand (cta-blue / primary)
  --brand-secondary
  --brand-accent
  --brand-gradient
  --brand-logomark
  --brand-link
  --brand-link-on-dark
  --brand-text-on-primary // to be used with text on the base primary (for best contrast)

See this PR for the full migration pattern — https://github.com/hashicorp/mktg-global-styles/pull/6
*/

:root {
	/**
     * Web Pattern Library color tokens
     */

	/* Neutral */
	--wpl-neutral-0: hsla(220deg, 2%, 100%, 1);
	--wpl-neutral-25: hsla(220deg, 2%, 96%, 1);
	--wpl-neutral-50: hsla(220deg, 2%, 92%, 1);
	--wpl-neutral-75: var(--wpl-neutral-100);
	--wpl-neutral-100: hsla(220deg, 2%, 80%, 1);
	--wpl-neutral-200: hsla(220deg, 2%, 68%, 1);
	--wpl-neutral-300: hsla(220deg, 2%, 63%, 1);
	--wpl-neutral-400: hsla(220deg, 2%, 58%, 1);
	--wpl-neutral-500: hsla(220deg, 2%, 46%, 1);
	--wpl-neutral-600: hsla(220deg, 2%, 35%, 1);
	--wpl-neutral-700: hsla(220deg, 2%, 26%, 1);
	--wpl-neutral-800: hsla(220deg, 2%, 19%, 1);
	--wpl-neutral-825: var(--wpl-neutral-800);
	--wpl-neutral-850: var(--wpl-neutral-800);
	--wpl-neutral-875: var(--wpl-neutral-800);
	--wpl-neutral-900: hsla(220deg, 2%, 0%, 1);
	--wpl-neutral-alpha-8: hsla(220deg, 2%, 0%, 0.08);
	--wpl-neutral-alpha-16: hsla(220deg, 2%, 0%, 0.16);
	--wpl-neutral-alpha-24: hsla(220deg, 2%, 0%, 0.24);
	--wpl-neutral-alpha-32: hsla(220deg, 2%, 0%, 0.32);
	--wpl-neutral-alpha-40: hsla(220deg, 2%, 0%, 0.4);
	--wpl-neutral-alpha-48: hsla(220deg, 2%, 0%, 0.48);

	/* Blue */
	--wpl-blue-25: hsla(220deg, 80%, 98%, 1);
	--wpl-blue-50: hsla(220deg, 80%, 93%, 1);
	--wpl-blue-75: var(--wpl-blue-100);
	--wpl-blue-100: hsla(220deg, 80%, 83%, 1);
	--wpl-blue-200: hsla(220deg, 80%, 74%, 1);
	--wpl-blue-300: hsla(220deg, 80%, 69%, 1);
	--wpl-blue-400: hsla(220deg, 80%, 66%, 1);
	--wpl-blue-500: hsla(220deg, 80%, 56%, 1);
	--wpl-blue-600: hsla(220deg, 80%, 45%, 1);
	--wpl-blue-700: hsla(220deg, 80%, 37%, 1);
	--wpl-blue-800: hsla(220deg, 80%, 29%, 1);
	--wpl-blue-900: var(--wpl-blue-800);
	--wpl-blue-alpha-8: hsla(220deg, 80%, 56%, 0.08);
	--wpl-blue-alpha-16: hsla(220deg, 80%, 56%, 0.16);
	--wpl-blue-alpha-24: hsla(220deg, 80%, 56%, 0.24);
	--wpl-blue-alpha-32: hsla(220deg, 80%, 56%, 0.32);
	--wpl-blue-alpha-40: hsla(220deg, 80%, 56%, 0.4);
	--wpl-blue-alpha-48: hsla(220deg, 80%, 56%, 0.48);

	/* Green */
	--wpl-green-25: #f0f9f3;
	--wpl-green-50: #e9f6ec;
	--wpl-green-75: #dbf0e0;
	--wpl-green-100: #ccebd3;
	--wpl-green-200: #99d6a8;
	--wpl-green-300: #66c27d;
	--wpl-green-400: #34ad52;
	--wpl-green-500: #009926;
	--wpl-green-600: #007a1f;
	--wpl-green-700: #005c17;
	--wpl-green-800: #003d0f;
	--wpl-green-900: #002409;

	/* Amber */
	--wpl-amber-25: #fcf7f3;
	--wpl-amber-50: #f9efe7;
	--wpl-amber-75: #f6e8da;
	--wpl-amber-100: #f2ddca;
	--wpl-amber-200: #e6be99;
	--wpl-amber-300: #d89c64;
	--wpl-amber-400: #c87a32;
	--wpl-amber-500: #bd5b00;
	--wpl-amber-600: #944700;
	--wpl-amber-700: #703600;
	--wpl-amber-800: #4d2500;
	--wpl-amber-900: #241100;

	/* Red */
	--wpl-red-25: #fef6f6;
	--wpl-red-50: #fce8e9;
	--wpl-red-75: #fbdfe0;
	--wpl-red-100: #fad1d3;
	--wpl-red-200: #f5a8ab;
	--wpl-red-300: #ef7b7f;
	--wpl-red-400: #eb5257;
	--wpl-red-500: #e5242a;
	--wpl-red-600: #c0161c;
	--wpl-red-700: #8d1115;
	--wpl-red-800: #600b0e;
	--wpl-red-900: #2e0507;

	/* Purple */
	--wpl-purple-25: #fbf5ff;
	--wpl-purple-50: #f6ebff;
	--wpl-purple-75: #f2e0ff;
	--wpl-purple-100: #edd6ff;
	--wpl-purple-200: #dcadff;
	--wpl-purple-300: #ca85ff;
	--wpl-purple-400: #ba61ff;
	--wpl-purple-500: #a938ff;
	--wpl-purple-600: #882cce;
	--wpl-purple-700: #642097;
	--wpl-purple-800: #431565;
	--wpl-purple-900: #210b32;

	/* Gradients */
	--wpl-gradient-angle-diagonal: 210deg;
	--wpl-gradient-angle-horizontal: 90deg;
	--wpl-gradient-angle-vertical: 180deg;

	--wpl-gradient-stops-corporate: #b5d9fa 0%, #1a5ee5 100%;
	--wpl-gradient-stops-infrastructure: #8cdeff 0%, #8763d9 100%;
	--wpl-gradient-stops-security: #ffe58c 0%, #f26b63 100%;
	--wpl-gradient-stops-networking: #ffb2b8 0%, #c74fb8 100%;
	--wpl-gradient-stops-applications: #a3f7d6 0%, #5cd1d9 50%, #5494e3 100%;

	--wpl-gradient-corporate-diagonal: linear-gradient(
		var(--wpl-gradient-angle-diagonal),
		var(--wpl-gradient-stops-corporate)
	);
	--wpl-gradient-corporate-horizontal: linear-gradient(
		var(--wpl-gradient-angle-horizontal),
		var(--wpl-gradient-stops-corporate)
	);
	--wpl-gradient-corporate-vertical: linear-gradient(
		var(--wpl-gradient-angle-vertical),
		var(--wpl-gradient-stops-corporate)
	);
	--wpl-gradient-infrastructure-diagonal: linear-gradient(
		var(--wpl-gradient-angle-diagonal),
		var(--wpl-gradient-stops-infrastructure)
	);
	--wpl-gradient-infrastructure-horizontal: linear-gradient(
		var(--wpl-gradient-angle-horizontal),
		var(--wpl-gradient-stops-infrastructure)
	);
	--wpl-gradient-infrastructure-vertical: linear-gradient(
		var(--wpl-gradient-angle-vertical),
		var(--wpl-gradient-stops-infrastructure)
	);
	--wpl-gradient-security-diagonal: linear-gradient(
		var(--wpl-gradient-angle-diagonal),
		var(--wpl-gradient-stops-security)
	);
	--wpl-gradient-security-horizontal: linear-gradient(
		var(--wpl-gradient-angle-horizontal),
		var(--wpl-gradient-stops-security)
	);
	--wpl-gradient-security-vertical: linear-gradient(
		var(--wpl-gradient-angle-vertical),
		var(--wpl-gradient-stops-security)
	);
	--wpl-gradient-networking-diagonal: linear-gradient(
		var(--wpl-gradient-angle-diagonal),
		var(--wpl-gradient-stops-networking)
	);
	--wpl-gradient-networking-horizontal: linear-gradient(
		var(--wpl-gradient-angle-horizontal),
		var(--wpl-gradient-stops-networking)
	);
	--wpl-gradient-networking-vertical: linear-gradient(
		var(--wpl-gradient-angle-vertical),
		var(--wpl-gradient-stops-networking)
	);
	--wpl-gradient-applications-diagonal: linear-gradient(
		var(--wpl-gradient-angle-diagonal),
		var(--wpl-gradient-stops-applications)
	);
	--wpl-gradient-applications-horizontal: linear-gradient(
		var(--wpl-gradient-angle-horizontal),
		var(--wpl-gradient-stops-applications)
	);
	--wpl-gradient-applications-vertical: linear-gradient(
		var(--wpl-gradient-angle-vertical),
		var(--wpl-gradient-stops-applications)
	);

	/* Grays */
	--white: #ffffff;
	--black: #000000;
	--gray-6: #f2f2f3;
	--gray-5: #dbdbdc;
	--gray-4: #bfbfc0;
	--gray-3: #727274;
	--gray-2: #343536;
	--gray-1: #1d1e1f;

	/* HashiCorp brand color */
	--brand: #2e71e5; /* CTA-Blue */
	--brand-secondary: #f2f2f3;
	--brand-logomark: #000000;
	--brand-accent: #f2f2f3;
	--brand-link: #2264d6;
	--brand-link-on-dark: #4294ff;
	--brand-text-on-primary: var(--white);
	--brand-gradient: linear-gradient(50.7deg, #63d0ff 0%, #844fba 100%);

	/* Boundary */
	--boundary: #ec585d;
	--boundary-logomark: #f24c53;
	--boundary-secondary: #ffecec;
	--boundary-link: #d3353f;
	--boundary-link-on-dark: #ff6068;
	--boundary-text-on-primary: var(--white);
	--boundary-accent: #fff4d4;
	--boundary-gradient: linear-gradient(
		50.7deg,
		var(--boundary-accent) 0%,
		var(--boundary) 100%
	);
	--boundary-alpha-8: hsla(358deg, 80%, 64%, 0.08);
	--boundary-alpha-16: hsla(358deg, 80%, 64%, 0.16);
	--boundary-alpha-24: hsla(358deg, 80%, 64%, 0.24);
	--boundary-alpha-32: hsla(358deg, 80%, 64%, 0.32);
	--boundary-alpha-40: hsla(358deg, 80%, 64%, 0.4);
	--boundary-alpha-48: hsla(358deg, 80%, 64%, 0.48);

	/* Consul */
	--consul: #dc477d;
	--consul-logomark: #e84580;
	--consul-secondary: #ffe9f1;
	--consul-link: #ca3066;
	--consul-link-on-dark: #f85c94;
	--consul-text-on-primary: var(--white);
	--consul-accent: #d1ebff;
	--consul-gradient: linear-gradient(
		50.7deg,
		var(--consul-accent) 0%,
		var(--consul) 100%
	);
	--consul-alpha-8: hsla(338deg, 68%, 57%, 0.08);
	--consul-alpha-16: hsla(338deg, 68%, 57%, 0.16);
	--consul-alpha-24: hsla(338deg, 68%, 57%, 0.24);
	--consul-alpha-32: hsla(338deg, 68%, 57%, 0.32);
	--consul-alpha-40: hsla(338deg, 68%, 57%, 0.4);
	--consul-alpha-48: hsla(338deg, 68%, 57%, 0.48);

	/* Nomad */
	--nomad: #60dea9;
	--nomad-logomark: #06d092;
	--nomad-secondary: #d3fdeb;
	--nomad-link: #008661;
	--nomad-link-on-dark: #60dea9;
	--nomad-text-on-primary: var(--black);
	--nomad-accent: #f1fdcd;
	--nomad-gradient: linear-gradient(
		50.7deg,
		var(--nomad-accent) 0%,
		var(--nomad) 100%
	);
	--nomad-alpha-8: hsla(155deg, 66%, 62%, 0.08);
	--nomad-alpha-16: hsla(155deg, 66%, 62%, 0.16);
	--nomad-alpha-24: hsla(155deg, 66%, 62%, 0.24);
	--nomad-alpha-32: hsla(155deg, 66%, 62%, 0.32);
	--nomad-alpha-40: hsla(155deg, 66%, 62%, 0.4);
	--nomad-alpha-48: hsla(155deg, 66%, 62%, 0.48);

	/* Packer */
	--packer: #63d0ff;
	--packer-logomark: #02a8ef;
	--packer-secondary: #d4f2ff;
	--packer-link: #007db4;
	--packer-link-on-dark: #63d0ff;
	--packer-text-on-primary: var(--black);
	--packer-accent: #cefcf2;
	--packer-gradient: linear-gradient(
		50.7deg,
		var(--packer-accent) 0%,
		var(--packer) 100%
	);
	--packer-alpha-8: hsla(198deg, 100%, 69%, 0.08);
	--packer-alpha-16: hsla(198deg, 100%, 69%, 0.16);
	--packer-alpha-24: hsla(198deg, 100%, 69%, 0.24);
	--packer-alpha-32: hsla(198deg, 100%, 69%, 0.32);
	--packer-alpha-40: hsla(198deg, 100%, 69%, 0.4);
	--packer-alpha-48: hsla(198deg, 100%, 69%, 0.48);

	/* Terraform */
	--terraform: #844fba;
	--terraform-logomark: #7b42bc;
	--terraform-secondary: #f4ecff;
	--terraform-link: #8040c9;
	--terraform-link-on-dark: #ac72f0;
	--terraform-text-on-primary: var(--white);
	--terraform-accent: #d1ebff;
	--terraform-gradient: linear-gradient(
		50.7deg,
		var(--terraform-accent) 0%,
		var(--terraform) 100%
	);
	--terraform-alpha-8: hsla(270deg, 44%, 52%, 0.08);
	--terraform-alpha-16: hsla(270deg, 44%, 52%, 0.16);
	--terraform-alpha-24: hsla(270deg, 44%, 52%, 0.24);
	--terraform-alpha-32: hsla(270deg, 44%, 52%, 0.32);
	--terraform-alpha-40: hsla(270deg, 44%, 52%, 0.4);
	--terraform-alpha-48: hsla(270deg, 44%, 52%, 0.48);

	/* Vagrant */
	--vagrant: #2e71e5;
	--vagrant-logomark: #1868f2;
	--vagrant-secondary: #d6ebff;
	--vagrant-link: #2264d6;
	--vagrant-link-on-dark: #4294ff;
	--vagrant-text-on-primary: var(--white);
	--vagrant-accent: #d2f8fe;
	--vagrant-gradient: linear-gradient(
		50.7deg,
		var(--vagrant-accent) 0%,
		var(--vagrant) 100%
	);
	--vagrant-alpha-8: hsla(218deg, 78%, 54%, 0.08);
	--vagrant-alpha-16: hsla(218deg, 78%, 54%, 0.16);
	--vagrant-alpha-24: hsla(218deg, 78%, 54%, 0.24);
	--vagrant-alpha-32: hsla(218deg, 78%, 54%, 0.32);
	--vagrant-alpha-40: hsla(218deg, 78%, 54%, 0.4);
	--vagrant-alpha-48: hsla(218deg, 78%, 54%, 0.48);

	/* Vault */
	--vault: #ffec6e;
	--vault-logomark: #000000;
	--vault-secondary: #fff9cf;
	--vault-link: #9c6d03;
	--vault-link-on-dark: #ffd814;
	--vault-text-on-primary: var(--black);
	--vault-accent: #f2f2f3;
	--vault-gradient: linear-gradient(
		50.7deg,
		var(--vault-accent) 0%,
		var(--vault) 100%
	);
	--vault-alpha-8: hsla(52deg, 100%, 72%, 0.08);
	--vault-alpha-16: hsla(52deg, 100%, 72%, 0.16);
	--vault-alpha-24: hsla(52deg, 100%, 72%, 0.24);
	--vault-alpha-32: hsla(52deg, 100%, 72%, 0.32);
	--vault-alpha-40: hsla(52deg, 100%, 72%, 0.4);
	--vault-alpha-48: hsla(52deg, 100%, 72%, 0.48);

	/* Waypoint */
	--waypoint: #62d4dc;
	--waypoint-logomark: #14c6cb;
	--waypoint-secondary: #d7f8fa;
	--waypoint-link: #008196;
	--waypoint-link-on-dark: #62d4dc;
	--waypoint-text-on-primary: var(--black);
	--waypoint-accent: #d3fddb;
	--waypoint-gradient: linear-gradient(
		50.7deg,
		var(--waypoint-accent) 0%,
		var(--waypoint) 100%
	);
	--waypoint-alpha-8: hsla(184deg, 64%, 62%, 0.08);
	--waypoint-alpha-16: hsla(184deg, 64%, 62%, 0.16);
	--waypoint-alpha-24: hsla(184deg, 64%, 62%, 0.24);
	--waypoint-alpha-32: hsla(184deg, 64%, 62%, 0.32);
	--waypoint-alpha-40: hsla(184deg, 64%, 62%, 0.4);
	--waypoint-alpha-48: hsla(184deg, 64%, 62%, 0.48);

	/* Info */
	--info-l2: #eff5ff;
	--info-l1: #d0e0ff;
	--info: #1f69ff;
	--info-d1: #0d44cc;

	/* Warning */
	--warning-l2: #fcf6ea;
	--warning-l1: #f9eacd;
	--warning: #eaaa32;
	--warning-d1: #975b06;

	/* Danger */
	--danger-l2: #fcf0f2;
	--danger-l1: #ffd4d6;
	--danger: #f25054;
	--danger-d1: #ba2226;

	/* Success */
	--success-l2: #ebfdf7;
	--success-l1: #c1f1e0;
	--success: #00bc7f;
	--success-d1: #007854;

	/* Visited */
	--visited: #5c4ee5;
	--visited-d1: #4c40bc;

	/* Code */
	--code-dark: #252937;
	--code-light: #efeff0;
	--code-light-transparent: rgba(29, 30, 35, 0.07);
	--code-comments: #75715e;
	--code-strings: #e6db74;
	--code-numbers: #ae81ff;
	--code-operators: #f92672;
	--code-properties: #a6e22e;
	--code-control: #66d9ef;
}
