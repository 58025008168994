.hdsplus-typography-display-500 { font-family: var(--hdsplus-typography-display-500-font-family); font-size: var(--hdsplus-typography-display-500-font-size); line-height: var(--hdsplus-typography-display-500-line-height); letter-spacing: var(--hdsplus-typography-display-500-letter-spacing); text-transform: var(--hdsplus-typography-display-500-text-transform); margin: 0; padding: 0; }
.hdsplus-typography-display-400 { font-family: var(--hdsplus-typography-display-400-font-family); font-size: var(--hdsplus-typography-display-400-font-size); line-height: var(--hdsplus-typography-display-400-line-height); letter-spacing: var(--hdsplus-typography-display-400-letter-spacing); text-transform: var(--hdsplus-typography-display-400-text-transform); margin: 0; padding: 0; }
.hdsplus-typography-display-300 { font-family: var(--hdsplus-typography-display-300-font-family); font-size: var(--hdsplus-typography-display-300-font-size); line-height: var(--hdsplus-typography-display-300-line-height); letter-spacing: var(--hdsplus-typography-display-300-letter-spacing); text-transform: var(--hdsplus-typography-display-300-text-transform); margin: 0; padding: 0; }
.hdsplus-typography-display-200 { font-family: var(--hdsplus-typography-display-200-font-family); font-size: var(--hdsplus-typography-display-200-font-size); line-height: var(--hdsplus-typography-display-200-line-height); letter-spacing: var(--hdsplus-typography-display-200-letter-spacing); text-transform: var(--hdsplus-typography-display-200-text-transform); margin: 0; padding: 0; }
.hdsplus-typography-display-100 { font-family: var(--hdsplus-typography-display-100-font-family); font-size: var(--hdsplus-typography-display-100-font-size); line-height: var(--hdsplus-typography-display-100-line-height); letter-spacing: var(--hdsplus-typography-display-100-letter-spacing); text-transform: var(--hdsplus-typography-display-100-text-transform); margin: 0; padding: 0; }
.hdsplus-typography-body-300 { font-family: var(--hdsplus-typography-body-300-font-family); font-size: var(--hdsplus-typography-body-300-font-size); line-height: var(--hdsplus-typography-body-300-line-height); letter-spacing: var(--hdsplus-typography-body-300-letter-spacing); text-transform: var(--hdsplus-typography-body-300-text-transform); margin: 0; padding: 0; }
.hdsplus-typography-body-200 { font-family: var(--hdsplus-typography-body-200-font-family); font-size: var(--hdsplus-typography-body-200-font-size); line-height: var(--hdsplus-typography-body-200-line-height); letter-spacing: var(--hdsplus-typography-body-200-letter-spacing); text-transform: var(--hdsplus-typography-body-200-text-transform); margin: 0; padding: 0; }
.hdsplus-typography-body-100 { font-family: var(--hdsplus-typography-body-100-font-family); font-size: var(--hdsplus-typography-body-100-font-size); line-height: var(--hdsplus-typography-body-100-line-height); letter-spacing: var(--hdsplus-typography-body-100-letter-spacing); text-transform: var(--hdsplus-typography-body-100-text-transform); margin: 0; padding: 0; }
.hdsplus-typography-code-100 { font-family: var(--hdsplus-typography-code-100-font-family); font-size: var(--hdsplus-typography-code-100-font-size); line-height: var(--hdsplus-typography-code-100-line-height); letter-spacing: var(--hdsplus-typography-code-100-letter-spacing); text-transform: var(--hdsplus-typography-code-100-text-transform); margin: 0; padding: 0; }
.hdsplus-typography-code-200 { font-family: var(--hdsplus-typography-code-200-font-family); font-size: var(--hdsplus-typography-code-200-font-size); line-height: var(--hdsplus-typography-code-200-line-height); letter-spacing: var(--hdsplus-typography-code-200-letter-spacing); text-transform: var(--hdsplus-typography-code-200-text-transform); margin: 0; padding: 0; }
.hdsplus-typography-code-300 { font-family: var(--hdsplus-typography-code-300-font-family); font-size: var(--hdsplus-typography-code-300-font-size); line-height: var(--hdsplus-typography-code-300-line-height); letter-spacing: var(--hdsplus-typography-code-300-letter-spacing); text-transform: var(--hdsplus-typography-code-300-text-transform); margin: 0; padding: 0; }
.hdsplus-typography-display-expressive-100 { font-family: var(--hdsplus-typography-display-expressive-100-font-family); font-size: var(--hdsplus-typography-display-expressive-100-font-size); line-height: var(--hdsplus-typography-display-expressive-100-line-height); letter-spacing: var(--hdsplus-typography-display-expressive-100-letter-spacing); text-transform: var(--hdsplus-typography-display-expressive-100-text-transform); margin: 0; padding: 0; }
.hdsplus-typography-display-expressive-200 { font-family: var(--hdsplus-typography-display-expressive-200-font-family); font-size: var(--hdsplus-typography-display-expressive-200-font-size); line-height: var(--hdsplus-typography-display-expressive-200-line-height); letter-spacing: var(--hdsplus-typography-display-expressive-200-letter-spacing); text-transform: var(--hdsplus-typography-display-expressive-200-text-transform); margin: 0; padding: 0; }
.hdsplus-typography-display-expressive-300 { font-family: var(--hdsplus-typography-display-expressive-300-font-family); font-size: var(--hdsplus-typography-display-expressive-300-font-size); line-height: var(--hdsplus-typography-display-expressive-300-line-height); letter-spacing: var(--hdsplus-typography-display-expressive-300-letter-spacing); text-transform: var(--hdsplus-typography-display-expressive-300-text-transform); margin: 0; padding: 0; }
.hdsplus-typography-display-expressive-400 { font-family: var(--hdsplus-typography-display-expressive-400-font-family); font-size: var(--hdsplus-typography-display-expressive-400-font-size); line-height: var(--hdsplus-typography-display-expressive-400-line-height); letter-spacing: var(--hdsplus-typography-display-expressive-400-letter-spacing); text-transform: var(--hdsplus-typography-display-expressive-400-text-transform); margin: 0; padding: 0; }
.hdsplus-typography-display-expressive-500 { font-family: var(--hdsplus-typography-display-expressive-500-font-family); font-size: var(--hdsplus-typography-display-expressive-500-font-size); line-height: var(--hdsplus-typography-display-expressive-500-line-height); letter-spacing: var(--hdsplus-typography-display-expressive-500-letter-spacing); text-transform: var(--hdsplus-typography-display-expressive-500-text-transform); margin: 0; padding: 0; }
.hdsplus-typography-display-expressive-600 { font-family: var(--hdsplus-typography-display-expressive-600-font-family); font-size: var(--hdsplus-typography-display-expressive-600-font-size); line-height: var(--hdsplus-typography-display-expressive-600-line-height); letter-spacing: var(--hdsplus-typography-display-expressive-600-letter-spacing); text-transform: var(--hdsplus-typography-display-expressive-600-text-transform); margin: 0; padding: 0; }
.hdsplus-typography-display-expressive-700 { font-family: var(--hdsplus-typography-display-expressive-700-font-family); font-size: var(--hdsplus-typography-display-expressive-700-font-size); line-height: var(--hdsplus-typography-display-expressive-700-line-height); letter-spacing: var(--hdsplus-typography-display-expressive-700-letter-spacing); text-transform: var(--hdsplus-typography-display-expressive-700-text-transform); margin: 0; padding: 0; }
.hdsplus-typography-display-expressive-800 { font-family: var(--hdsplus-typography-display-expressive-800-font-family); font-size: var(--hdsplus-typography-display-expressive-800-font-size); line-height: var(--hdsplus-typography-display-expressive-800-line-height); letter-spacing: var(--hdsplus-typography-display-expressive-800-letter-spacing); text-transform: var(--hdsplus-typography-display-expressive-800-text-transform); margin: 0; padding: 0; }
.hdsplus-typography-body-400 { font-family: var(--hdsplus-typography-body-400-font-family); font-size: var(--hdsplus-typography-body-400-font-size); line-height: var(--hdsplus-typography-body-400-line-height); letter-spacing: var(--hdsplus-typography-body-400-letter-spacing); text-transform: var(--hdsplus-typography-body-400-text-transform); margin: 0; padding: 0; }
.hdsplus-typography-label { font-family: var(--hdsplus-typography-label-font-family); font-size: var(--hdsplus-typography-label-font-size); line-height: var(--hdsplus-typography-label-line-height); letter-spacing: var(--hdsplus-typography-label-letter-spacing); text-transform: var(--hdsplus-typography-label-text-transform); margin: 0; padding: 0; }
