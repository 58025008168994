/*

Note: 

These heading styles are a snapshot of a previous version
of our global typography classes. They are no longer something
we want to rely on - instead, our typography classes should be used
(or extended via "compose" in CSS modules).

However, we've retained these styles for now, as removing them
from the hashicorp-www-next was a large enough task that
it did not feel in scope during the transition to CSS modules.

Task to deprecate these styles, and instead use
typography styles as defined by design: 
https://app.asana.com/0/1100423001970639/1200632621534164/f

*/
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: var(--font-display);
	font-weight: var(--font-weight-bold);
	font-kerning: normal;
}

h1 {
	font-size: 2.125rem;
	letter-spacing: -0.008em;
	line-height: 1.265em;

	@media (--medium-up) {
		font-size: 2.625rem;
		letter-spacing: -0.01em;
		line-height: 1.19em;
	}

	@media (--large) {
		font-size: 3.125rem;
		line-height: 1.2em;
	}
}

h2 {
	font-size: 1.75rem;
	letter-spacing: -0.004em;
	line-height: 1.286em;
	margin: 1em 0;

	@media (--medium-up) {
		font-size: 2.125rem;
		letter-spacing: -0.008em;
		line-height: 1.265em;
	}

	@media (--large) {
		font-size: 2.5rem;
		letter-spacing: -0.01em;
		line-height: 1.25em;
	}
}

h3 {
	font-family: var(--font-display);
	font-weight: var(--font-weight-bold);
	font-size: 1.5rem;
	letter-spacing: -0.004em;
	line-height: 1.375em;
	margin: 1em 0;

	@media (--medium-up) {
		font-size: 1.75rem;
		line-height: 1.321em;
	}

	@media (--large) {
		font-size: 2rem;
		letter-spacing: -0.006em;
		line-height: 1.313em;
	}
}

h4 {
	font-size: 1.313rem;
	line-height: 1.429em;

	@media (--medium-up) {
		font-size: 1.438rem;
		letter-spacing: -0.004em;
		line-height: 1.391em;
	}

	@media (--large) {
		font-size: 1.5rem;
		line-height: 1.417em;
	}
}

h5 {
	font-size: 1.125rem;
	line-height: 1.556em;
}

h6 {
	font-size: 1rem;
	line-height: 1.5em;
}
