/* Grid container */

.g-grid-container {
	position: relative;
	width: 100%;
	margin-left: auto;
	margin-right: auto;

	@media (--small) {
		padding-left: 24px;
		padding-right: 24px;
		max-width: 616px;
	}

	@media (--medium) {
		padding-left: 40px;
		padding-right: 40px;
		max-width: 944px;
	}

	@media (--large) {
		padding-left: 48px;
		padding-right: 48px;
		max-width: 1312px;
	}
}
