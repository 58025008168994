/*
 * Grid system
 * https://www.figma.com/file/8iryEikGV4Z5fVYmQH4WTi/01---Visual-Guidelines-v3.0?node-id=4969%3A15733
 */
.g-grid {
	position: relative;
	display: grid;
	grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
	column-gap: var(--column-gap);

	@media (--small) {
		--columns: 4;
		--column-gap: 16px;
	}

	@media (--medium) {
		--columns: 12;
		--column-gap: 24px;
	}

	@media (--large) {
		--columns: 12;
		--column-gap: 32px;
	}
}
