/* Border box is just better */
*,
*::after,
*::before {
	box-sizing: border-box;
}

/* Base resets and font styling */
html {
	height: 100%;
	font-size: var(--font-size-default);
	-webkit-overflow-scrolling: touch;
}

body {
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0;
	font-family: var(--token-typography-font-stack-text);
	font-size: 16px;
	font-weight: var(--token-typography-font-weight-regular);
	line-height: 1.625;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	overflow-x: hidden;
	min-height: 100vh;
}

/* It's better to add this when necessary than constantly remove it */
a {
	text-decoration: none;
	color: var(--brand-link);
}

/*
Set our default monospace font
Note: in most cases we would prefer to use our code-block component,
or our code typography class, this is intended as a fallback.
*/
pre,
code {
	font-family: var(--token-typography-code-200-font-family);
}

/*
Opinion:
Horizontal rules are easier to style using
height and background color, so we set that
up as a default.
*/
hr {
	height: 1px;
	border: none;
	color: var(--gray-5);
	background-color: var(--gray-5);
}

.sr-only {
	position: absolute !important;
	width: 1px !important;
	height: 1px !important;
	margin: -1px !important;
	padding: 0 !important;
	overflow: hidden !important;
	white-space: nowrap !important;
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	clip-path: inset(50%) !important;
}
